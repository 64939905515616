import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import Layout from 'components/layout';
import Box from 'components/box';
import Head from 'components/head';

const PaymentFailed = ({ data }) => (
  <Layout>
    <Helmet>
      <meta name="robots" content="noindex, nofollow" />
    </Helmet>
    <Head pageTitle={data.paymentFailedJson.title} />
    <Box>
      <Img
        fixed={
          data.paymentFailedJson.image
            ? data.paymentFailedJson.image.childImageSharp.fixed
            : {}
        }
        alt="Payment Failed"
      />
      <div
        dangerouslySetInnerHTML={{
          __html: data.paymentFailedJson.content.childMarkdownRemark.html,
        }}
      />
    </Box>
  </Layout>
);

PaymentFailed.propTypes = {
  data: PropTypes.object.isRequired,
};

export default PaymentFailed;

export const query = graphql`
  query PaymentFailedQuery {
    paymentFailedJson {
      title
      content {
        childMarkdownRemark {
          html
        }
      }
      image {
        childImageSharp {
          fixed(width: 320) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  }
`;
